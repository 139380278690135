/*  @import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;500&display=swap'); */

.App {
    text-align: center;
}

.App-logo {
    pointer-events: none;
}

/* @media screen and (max-width: 400px) {
  body {
    background-color: #E2B1B1;
    color: white;
  }
} */

.App-header {
    background-color: #282c34;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}
