/* @font-face {
  font-family:'SF Pro Display';
  font-style:normal;
  font-weight:800;
  src:local('☺︎'), url("http://www.apple.com/wss/fonts/SF-Pro-Display/v1/sf-pro-display_heavy.woff") format("woff"), url("http://www.apple.com/wss/fonts/SF-Pro-Display/v1/sf-pro-display_heavy.ttf") format("truetype");
  / (C) 2015 Apple Inc. All rights reserved. /
} */

@font-face {
  font-family: 'PlusJakartaSans';
  font-weight: 550;

  src: local('PlusJakartaSans'), url(./fonts/Plus_Jakarta_Sans/PlusJakartaSans-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Rajdhani';
  font-weight: 550;

  src: local('Rajdhani'), url(./fonts/Rajdhani//Rajdhani-Bold.ttf) format('truetype');
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
